import request from '@/utils/request'


// 查询用户充值列表
export function listUserRecharge(query) {
  return request({
    url: '/platform/user-recharge/list',
    method: 'get',
    params: query
  })
}

// 查询用户充值分页
export function pageUserRecharge(query) {
  return request({
    url: '/platform/user-recharge/page',
    method: 'get',
    params: query
  })
}

// 查询用户充值详细
export function getUserRecharge(data) {
  return request({
    url: '/platform/user-recharge/detail',
    method: 'get',
    params: data
  })
}

// 新增用户充值
export function addUserRecharge(data) {
  return request({
    url: '/platform/user-recharge/add',
    method: 'post',
    data: data
  })
}

// 修改用户充值
export function updateUserRecharge(data) {
  return request({
    url: '/platform/user-recharge/edit',
    method: 'post',
    data: data
  })
}

// 删除用户充值
export function delUserRecharge(data) {
  return request({
    url: '/platform/user-recharge/delete',
    method: 'post',
    data: data
  })
}
