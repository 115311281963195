<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="訂單編號" prop="orderNo" >
        <a-input v-model="form.orderNo" placeholder="请输入訂單編號" />
      </a-form-model-item>
      <a-form-model-item label="用戶id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用戶id" />
      </a-form-model-item>
      <a-form-model-item label="充值金額" prop="rechargeMoney" >
        <a-input v-model="form.rechargeMoney" placeholder="请输入充值金額" />
      </a-form-model-item>
      <a-form-model-item label="支付類型 2-微信 3-支付宝 4-苹果内购" prop="paymentType" >
      </a-form-model-item>
      <a-form-model-item label="付款编号" prop="paymentNo" >
        <a-input v-model="form.paymentNo" placeholder="请输入付款编号" />
      </a-form-model-item>
      <a-form-model-item label="第三方訂單号" prop="outTradeNo" >
        <a-input v-model="form.outTradeNo" placeholder="请输入第三方訂單号" />
      </a-form-model-item>
      <a-form-model-item label="訂單狀態1待付款 2已完成 3已关闭" prop="orderStatus" >
      </a-form-model-item>
      <a-form-model-item label="訂單付款時間" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="訂單完成時間" prop="finishTime" >
        <a-date-picker style="width: 100%" v-model="form.finishTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="充值用途" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="備註" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUserRecharge, addUserRecharge, updateUserRecharge } from '@/api/platform/userRecharge'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        orderNo: null,

        userId: null,

        rechargeMoney: null,

        paymentType: null,

        paymentNo: null,

        outTradeNo: null,

        orderStatus: 0,

        payTime: null,

        finishTime: null,

        type: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        paymentType: [
          { required: true, message: '支付類型 2-微信 3-支付宝 4-苹果内购不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        orderNo: null,
        userId: null,
        rechargeMoney: null,
        paymentType: null,
        paymentNo: null,
        outTradeNo: null,
        orderStatus: 0,
        payTime: null,
        finishTime: null,
        type: null,
        createTime: null,
        remark: null,
      }
     /* this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserRecharge({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUserRecharge(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUserRecharge(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
